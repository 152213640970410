import React, { useEffect, useState } from 'react';
import { Booking } from '../../components/Booking/Booking';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { BookingsTourHeading } from '../../components/BookingsTourHeading';
import { NewBookingModal } from '../../components/AddBookingModal/AddBookingModal';
import { Button, TextArea, Row, ComboBox } from '@carbon/react';
import { fetchGuides } from './ManageGuidesModalUtils';

import {
  Add,
  ChevronLeft,
  Copy,
  Catalog,
  Flag,
  Warning,
} from '@carbon/react/icons';
import config from '../../config';
import { customFetch } from '../../helpers/customFetch';
import { BookingsTourLink } from '../../components/BookingNearbyTourLink/BookingNearbyTourLink';
import ModalStateManager from './ModalStateManager';
import FetchNearbyTours from './FetchNearbyTours';
import { useRefreshBookings } from '../../helpers/refreshBookings';
import { formatResult } from './ToursPageUtils';
import { ManageGuidesModal } from './ManageGuidesModal';
import { canModify } from '../../helpers/bookingsHelper';
const ToursPage = () => {
  const [dailyBookings, setDailyBookings] = useState([]);
  const { day, time, tour } = useParams();
  const { user } = useAuthContext();
  const sources = user.company.sources;
  const hideSourcesCopyOnly = user.company.hideSourcesCopyOnly || false;
  const displaySources = user.company.display || user.role === 'Manager';
  const [nearbyTours, setNearbyTours] = useState([]);
  const [shift, setShift] = useState();
  const [chosenGuide, setChosenGuide] = useState('');

  // console.log("displaySource", displaySources);
  const displayedNearbyTours = nearbyTours?.map((item) => ({
    key: `${item.time}${item.tour}`,
    ...item,
  }));

  const refreshBookings = useRefreshBookings(day, time, tour, setDailyBookings);

  const [guides, setGuides] = useState([]);
  const company = user.company.name;
  useEffect(() => {
    fetchGuides(user, setGuides);
  }, [user]);

  // Fetch shift
  useEffect(() => {
    const fetchShift = async () => {
      const response = await fetch(
        `${config.apiUrl}/api/shift/${time}/${day}/${tour}/${user.company.name}`
      );
      if (response.ok) {
        const fetchedShift = await response.json();
        if (JSON.stringify(fetchedShift) !== JSON.stringify(shift)) {
          setShift(fetchedShift); // Update the shift state
        }
      } else if (response.status === 404) {
        console.log('Shift not found in tours page');
        setShift(null);
      }
    };

    fetchShift();
  }, [time, day, tour, user.company.name]);

  useEffect(() => {
    // Reset the arr state to an empty array with the same length as dailyBookings
    setArr(new Array(dailyBookings.length).fill(0));
  }, [dailyBookings]);

  useEffect(() => {
    refreshBookings();
  }, [refreshBookings]);

  const sortedData =
    dailyBookings &&
    dailyBookings.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  let totalPax =
    sortedData?.reduce((total, booking) => total + booking.pax, 0) || 0;

  let totalPaxByCustomerType =
    sortedData?.reduce((total, booking) => {
      booking.customers.forEach((customer) => {
        Object.keys(customer).forEach((type) => {
          if (!total[type]) {
            total[type] = 0;
          }
          total[type] += customer[type];
        });
      });
      return total;
    }, {}) || {};

  let detailedTotalPax = formatResult(totalPaxByCustomerType);
  const [arr, setArr] = useState([]);
  const [totalGuests, setTotalGuests] = useState(0);

  const updateGuests = (num, index) => {
    arr[index] = num;
    setArr(arr);
    const sum = arr.reduce((a, b) => a + b, 0);
    setTotalGuests(sum);
  };

  // Add day to canModify
  const canModifyElements = canModify(user, time, day);
  const displayedDailyBookings =
    sortedData &&
    sortedData.map((booking, index) => {
      return (
        <Booking
          key={booking._id}
          {...booking}
          displaySources={displaySources}
          updateGuests={(num) => updateGuests(num, index)}
          refreshBookings={refreshBookings}
          canModify={canModifyElements}
          chosenGuide={chosenGuide}
        />
      );
    });

  // Use the functions
  // const detailedPax = sumValuesByKey(sortedData);
  // const formattedDetailedPax = formatResult(detailedPax);
  // console.log(formattedDetailedPax);
  // console.log(formattedDetailedPax);
  let navigate = useNavigate();
  const date = new Date(day + 'T00:00:00'); // Calculate the date once
  const calendarBarDate = new Intl.DateTimeFormat('en-UK', {
    timeZone: user.company.timezone,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date);

  const getBookingText = () => {
    return sortedData
      .map((booking) => {
        const customerDetails = booking.customers
          .map((customer) => {
            return Object.entries(customer)
              .map(([type, count]) => `${count} ${type}`)
              .join(', ');
          })
          .join(', ');

        return `${booking.name}, ${customerDetails}${
          hideSourcesCopyOnly ? '' : ` - ${booking.source}`
        }`;
      })
      .join('\n');
  };
  const copyBookingsToClipboard = async () => {
    const text = getBookingText();
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const [showTextArea, setShowTextArea] = useState(false);

  const handleClick = () => {
    setShowTextArea((prevShowTextArea) => !prevShowTextArea);
  };

  const uniqueId = `${day}-${time}-${tour}`;
  const [textAreaValue, setTextAreaValue] = useState('');
  const [tempValue, setTempValue] = useState('');
  const [showFullText, setShowFullText] = useState(false);

  const handleSaveClick = async (e) => {
    try {
      const response = await customFetch(`${config.apiUrl}/api/save`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uniqueId: uniqueId,
          note: tempValue,
        }),
      });

      if (response.ok) {
        // Fetch the newly saved note
        const fetchResponse = await customFetch(
          `${config.apiUrl}/api/${uniqueId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (fetchResponse.ok) {
          const note = await fetchResponse.json();
          // console.log(note.note);
          setTextAreaValue(note.note);
          setShowTextArea(false);
        } else {
          throw new Error('Error fetching the newly saved note');
        }
      } else {
        throw new Error('Error saving the note');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log("uniqueId", uniqueId);

  useEffect(() => {
    // Set empty state
    setTextAreaValue('');
    setTempValue('');

    const fetchNote = async () => {
      try {
        const response = await customFetch(`${config.apiUrl}/api/${uniqueId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        if (response.ok) {
          const note = await response.json();
          setTextAreaValue(note.note);
          setTempValue(note.note);
        } else {
          throw new Error('Error fetching data');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchNote();
  }, [uniqueId, user.token]);

  const handleCancelClick = () => {
    setTempValue(textAreaValue);
    setShowTextArea(false);
  };

  useEffect(() => {
    setShowTextArea(false);
  }, [day, time, tour]);
  // console.log("textAreaValue", textAreaValue);
  const [attendeesMismatch, setAttendeesMismatch] = useState(false);
  const tours = user.company.tours;
  const tourType = tours.find((tourItem) => tourItem.label === tour);
  const flag = tourType ? tourType.flag : 'None';
  const color = tourType ? tourType.color : 'default';

  const handleGuideChange = ({ selectedItem }) => {
    // Extract the _id from the selectedItem, if selectedItem is not null
    const selectedId = selectedItem ? selectedItem : '';
    setChosenGuide(selectedId);
  };
  const selectedGuide = guides.find((guide) => guide._id === chosenGuide._id);

  return (
    <>
      <FetchNearbyTours setNearbyTours={setNearbyTours} />
      <button
        className="back-button"
        onClick={() => {
          navigate(`/bookings/date/${day}`, { replace: true });
        }}
      >
        <ChevronLeft />
        <span className="back-button-text">Back</span>
      </button>
      <span className="date-uk">{calendarBarDate}</span>
      <section className="main-tour-page-container">
        <BookingsTourLink displayedNearbyTours={displayedNearbyTours} />
        <BookingsTourHeading
          time={time}
          tour={tour}
          totalGuests={totalGuests}
          totalPax={totalPax}
          detailedTotalPax={detailedTotalPax}
          renderIcon={Copy}
          copyBookingsToClipboard={copyBookingsToClipboard}
          handleClick={handleClick}
          color={color}
          flag={flag}
          shift={shift}
          setAttendeesMismatch={setAttendeesMismatch}
          canModify={canModifyElements}
        />
        <section className="upper-booking-section">
          <section className="notes-section">
            {showTextArea && (
              <Row>
                <TextArea
                  cols={35}
                  id={uniqueId}
                  labelText=""
                  invalidText="A valid value is required"
                  placeholder="Write note"
                  rows={3}
                  value={tempValue}
                  onChange={(event) => setTempValue(event.target.value)}
                />

                <div>
                  <Button onClick={handleSaveClick}>Save</Button>
                  <Button onClick={handleCancelClick} kind="secondary">
                    Cancel
                  </Button>
                </div>
              </Row>
            )}
          </section>
          <section>
            {!showTextArea && (
              <>
                {textAreaValue !== '' && (
                  <Row className="tour-notes">
                    <span className="wrapped-tour-note">
                      <Catalog className="wrapped-tour-note-icon" />
                      {showFullText
                        ? textAreaValue
                        : textAreaValue.length > 100
                        ? `${textAreaValue.substring(0, 97)}... `
                        : textAreaValue}
                      {textAreaValue.length > 100 && (
                        <button
                          onClick={() => setShowFullText(!showFullText)}
                          className="show-more-button"
                        >
                          {showFullText ? 'Show less' : 'Show more'}
                        </button>
                      )}
                    </span>
                  </Row>
                )}
              </>
            )}
          </section>
          <section className="upper-button-container">
            <ModalStateManager
              renderLauncher={({ setOpen }) => (
                <Button
                  renderIcon={attendeesMismatch ? Warning : Flag}
                  kind="tertiary"
                  className="flex-1"
                  onClick={async () => {
                    await refreshBookings();
                    setOpen(true);
                  }}
                >
                  {attendeesMismatch ? 'Split *' : 'Split'}
                </Button>
              )}
            >
              {({ open, setOpen }) => (
                <ManageGuidesModal
                  user={user}
                  tour={tour}
                  time={time}
                  open={open}
                  setOpen={setOpen}
                  sources={sources}
                  company={company}
                  date={day}
                  shift={shift}
                  setShift={setShift}
                  bookings={dailyBookings}
                  setBookings={setDailyBookings}
                />
              )}
            </ModalStateManager>
            <ModalStateManager
              renderLauncher={({ setOpen }) => (
                <Button
                  renderIcon={Add}
                  kind="tertiary"
                  className="flex-1"
                  onClick={() => setOpen(true)}
                >
                  Add Booking
                </Button>
              )}
            >
              {({ open, setOpen }) => (
                <NewBookingModal
                  user={user}
                  tour={tour}
                  time={time}
                  open={open}
                  setOpen={setOpen}
                  sources={sources}
                  onBookingAdded={refreshBookings}
                  day={day}
                />
              )}
            </ModalStateManager>
          </section>
        </section>
        <section>
          {user.assignable === true && (
            <div className="upper-booking-section">
              <ComboBox
                id="selectGuide"
                titleText="Check-in as the selected Guide"
                label="Guide"
                items={guides}
                itemToString={(item) => (item ? item.name : '')}
                onChange={handleGuideChange}
                key={Math.random()}
                selectedItem={selectedGuide}
              />
              <div className="assignable-centered">
                Selected:{' '}
                <span className="chosen-guide">{chosenGuide.name}</span>
              </div>
            </div>
          )}
        </section>
        <section>{displayedDailyBookings}</section>
      </section>
    </>
  );
};

export default ToursPage;
