import { useAuthContext } from '../../hooks/useAuthContext';
import { Toggle, DefinitionTooltip } from '@carbon/react';
import { useState, useEffect } from 'react';
import config from '../../config';
import { customFetch } from '../../helpers/customFetch';

const BookingToggle = ({
  _id,
  customers,
  bookingId,
  state,
  stateChanger,
  pax,
  canModify,
  chosenGuide,
}) => {
  const notZero = state !== 0;
  const { user } = useAuthContext();

  function toggleValuesToZero(arr) {
    return arr.map((obj) => {
      const newObj = {};
      for (const [key] of Object.entries(obj)) {
        newObj[key] = 0;
      }
      return newObj;
    });
  }

  const customersNoShow = toggleValuesToZero(customers);

  const updateAttendeesState = notZero ? 0 : pax;

  const handleToggleClick = async () => {
    const updateAttendees = notZero
      ? Object.values(customersNoShow)
      : customers;

    customFetch(`${config.apiUrl}/api/bookings/${_id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      method: 'PATCH',
      body: JSON.stringify({
        attendees: updateAttendees,
        _id: _id,
        chosenGuide: chosenGuide,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(data);
        stateChanger(updateAttendeesState);
      })
      .catch(function (error) {
        console.error('Error:', error);
      });
  };

  const CopyBookingIdButton = ({ bookingId }) => {
    const copyBookingIdToClipboard = async () => {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(bookingId);
      } else {
        return document.execCommand('copy', true, bookingId);
      }
    };

    return (
      <button className="copy-bookingId" onClick={copyBookingIdToClipboard}>
        {bookingId.length > 8 ? bookingId.substring(0, 7) + '...' : bookingId}
      </button>
    );
  };

  return (
    <Toggle
      disabled={!canModify}
      aria-label="toggle button"
      labelText={
        bookingId.length > 8 ? (
          <DefinitionTooltip definition={bookingId} align="bottom-right">
            <CopyBookingIdButton bookingId={bookingId} />
          </DefinitionTooltip>
        ) : bookingId.length === 0 ? (
          <span className="white">No Id</span>
        ) : (
          <CopyBookingIdButton bookingId={bookingId} />
        )
      }
      onClick={handleToggleClick}
      toggled={notZero}
      id={_id}
      labelA=""
      labelB=""
    />
  );
};

export { BookingToggle };
