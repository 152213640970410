import { useState } from "react";
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ComboBox,
  TextInput,
  Stack,
  Form,
  // InlineNotification,
  NumberInput,
} from "@carbon/react";
import config from "../../config";
import { customFetch } from "../../helpers/customFetch";

export function NewBookingModal({
  user,
  tour,
  time,
  open,
  setOpen,
  sources,
  onBookingAdded,
  day,
}) {
  const defaultSelectedItem = sources.find((item) => item.label === "Walkin");
  const [source, setSource] = useState(defaultSelectedItem.label);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const totalCustomers =
    children > 0
      ? [{ Adults: adults }, { Children: children }]
      : [{ Adults: adults }];

  const handleSubmit = async (e) => {
    e.preventDefault();

    setOpen(false);
    const booking = {
      company: user.company.name,
      name: name,
      customers: totalCustomers,
      pax: adults + children,
      attendees:
        user.company.name === "Napule Tours"
          ? []
          : user.guide
          ? totalCustomers
          : [],
      time: time,
      tour: tour,
      source: source,
      phone: phone,
      date: new Date(day),
    };

    const response = await customFetch(
      `${config.apiUrl}/api/bookings/add-booking`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(booking),
      }
    );

    // const data = await response.json();
    // console.log(data);
    if (response.ok) {
      onBookingAdded();
      setOpen(false);
    }
  };

  const isFormValid = () => {
    // Check if there is at least 1 adult and a name is provided
    return adults >= 1 && name !== "";
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ComposedModal open={open} onClose={() => setOpen(false)}>
        <ModalHeader title="Add New Booking" />
        <ModalBody>
          <Stack className="add-bookings-modal-stack" gap={8}>
            <ComboBox
              ariaLabel="New booking source dropdown"
              id="addNewBookingSource"
              items={sources}
              itemToString={(item) => (item ? item.label : "")}
              titleText="Source *"
              initialSelectedItem={defaultSelectedItem}
              placeholder="Search or Select the booking source"
              onChange={({ selectedItem }) =>
                setSource(selectedItem ? selectedItem.label : "")
              }
            />
            <NumberInput
              id="adults-number-input"
              label="Adults *"
              min={0}
              max={25}
              value={adults}
              onChange={(event, { value }) => setAdults(Number(value))}
            />
            <NumberInput
              id="children-number-input"
              label="Children"
              min={0}
              max={15}
              value={children}
              onChange={(event, { value }) => setChildren(Number(value))}
            />

            <TextInput
              id="addNewBookingName"
              invalidText="A valid value is required"
              labelText="Name *"
              placeholder="John Doe"
              onChange={(e) => setName(e.target.value)}
            />
            <TextInput
              id="addNewBookingPhone"
              invalidText="A valid value is required"
              labelText="Phone (optional)"
              placeholder="+34 123 456 789"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button kind="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isFormValid()}>
            Create Booking
          </Button>{" "}
        </ModalFooter>
      </ComposedModal>
    </Form>
  );
}
